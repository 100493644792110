import Constants from '../constants';
import { actionGetMyPointsSuccess } from '../store/Account/actions';
import store from '../store/store';
import Api from './api';
import {
    BillingInfoDTO,
    DomainPermissionDTO,
    ListingDTO,
    PurchasedInfo,
    PurchasedItemInfo,
    SaleInfo,
    UploadCredentialsDTO,
    UserDTO,
} from './model';
import { OssUploader } from './oss-uploader';

// TODO: #3059 - Refactor 'any'
/* eslint-disable @typescript-eslint/no-explicit-any */
export default class ApiUser extends Api {
    public static getMyUserProfile(): Promise<UserDTO> {
        return this.axios.get('/v1/user', { cache: false }).then((res) => {
            const profile: UserDTO = res.data;

            let domains: DomainPermissionDTO[] = [];
            if (res.data.domains) {
                domains = res.data.domains.map((d) => {
                    const dp: DomainPermissionDTO = {
                        domain: d.domain,
                        permission: this.apiPermissionsToEnum(d.permission.toString()),
                    };
                    return dp;
                });
            }
            profile.domains = domains;
            return profile;
        });
    }

    public static getAvatarUploadCredentials(filename: string): Promise<UploadCredentialsDTO> {
        return this.axios.get('/v1/user/avatar/' + filename, { cache: false }).then((res) => {
            return res.data;
        });
    }

    public static updateMyProfile(
        name?: string,
        bio?: string,
        twitterUrl?: string,
        facebookUrl?: string,
        instagramUrl?: string,
        websiteUrl?: string,
        linkedinUrl?: string,

        firstName?: string,
        lastName?: string,
        longDescription?: string,

        avatar?: File,
        avatarUrl?: string
    ): Promise<any> {
        const data = {
            name: name,
            bio: bio,
            twitterUrl: twitterUrl,
            facebookUrl: facebookUrl,
            instagramUrl: instagramUrl,
            websiteUrl: websiteUrl,
            linkedinUrl: linkedinUrl,

            firstName: firstName,
            lastName: lastName,
            longDescription: longDescription,

            avatarUrl: avatarUrl,
        };

        if (avatar) {
            return this.getAvatarUploadCredentials(avatar.name).then((credentials) => {
                const ossUploader = new OssUploader(credentials);
                return ossUploader.uploadFileToStorage(avatar, credentials.filename).then(() => {
                    const avatarUrl = Constants.AVATAR_URL + '/' + credentials.filename;
                    data['avatarUrl'] = avatarUrl;
                    return this.axios.put('/v1/user', data);
                });
            });
        } else {
            return this.axios.put('/v1/user', data);
        }
    }

    public static signup(
        email: string,
        password: string,
        name: string,
        firstName: string,
        lastName: string,
        newsletterSignUp: boolean
    ): Promise<string> {
        const data = {
            email: email,
            password: password,
            name: name,
            firstName: firstName,
            lastName: lastName,
            newsletterSignUp: newsletterSignUp,
        };
        return this.axios.post('/v1/user', data).then((res) => {
            return res.data.idToken;
        });
    }

    public static deleteUser(id: any): Promise<string> {
        return this.axios.delete('/v1/user', id).then((res) => {
            return res.data;
        });
    }

    public static requestPasswordResetPromise(email: string): Promise<any> {
        return this.axios.get(`/v1/user/reset_password?email=${encodeURIComponent(email)}`, { cache: false });
    }

    public static resetPasswordPromise(id: string, email: string, password?: string): Promise<any> {
        return this.axios.post('/v1/user/reset_password', {
            email: email,
            password: password,
            id: id,
        });
    }

    public static putBillingInfo(billingInfo: BillingInfoDTO): Promise<any> {
        return this.axios.put('/v1/user/billing', billingInfo).then((res) => {
            return res.data;
        });
    }

    public static getUserProfileByEth(wallet: string): Promise<UserDTO> {
        return this.axios.get('/v1/user/eth/' + wallet, { cache: false }).then((res) => {
            return res.data;
        });
    }

    public static getUserProfile(userId: string): Promise<UserDTO> {
        return this.axios.get('/v1/users/' + userId, { cache: true }).then((res) => {
            return res.data;
        });
    }

    public static getMyPurchases(): Promise<PurchasedInfo[]> {
        return this.axios.get('/v1/user/purchases', { cache: false }).then((result) => {
            const purchaseResults: PurchasedInfo[] = result.data.purchases.map((data) => {
                const purchaseItems: PurchasedItemInfo[] = data.items.map((item) => {
                    const amount = item.currency === 'USD' ? item.amount / 1000 : item.amount / (18 * 10);

                    const purchaseItemInfo: PurchasedItemInfo = {
                        id: item.id,
                        amount: amount,
                        currency: item.currency,
                        ownerId: item.ownerId,
                        paidAt: new Date(item.paidAt * 1000),
                        createdAt: new Date(item.createdAt * 1000),
                        purchaseId: item.purchaseId,
                        type: item.type,
                        typeData: item.typeData,
                        listingId: item.listingId ? item.listingId.toString() : '',
                    };
                    return purchaseItemInfo;
                });

                const purchaseInfo: PurchasedInfo = {
                    id: data.id,
                    amount: data.amount,
                    currency: data.currency,
                    paidAt: new Date(data.paidAt * 1000),
                    createdAt: new Date(data.createdAt * 1000),
                    receiptUrl: data.receiptUrl,
                    items: purchaseItems,
                };

                return purchaseInfo;
            });

            const orderPurchaseByDate = purchaseResults.sort((a, b) => b.paidAt.getTime() - a.paidAt.getTime());
            return orderPurchaseByDate;
        });
    }

    public static getUserListings(): Promise<ListingDTO[]> {
        return this.axios.get('/v1/user/listings', { cache: false, errorOnInvalidAuth: true }).then((res) => {
            return res.data.listings;
        });
    }

    public static getMySales(): Promise<SaleInfo[]> {
        return this.axios.get('/v1/user/sales', { cache: false }).then((result) => {
            const saleInfos: SaleInfo[] = result.data.sales.map((sale) => {
                let amount = sale.amount / 100;
                let fee = sale.fee / 100;
                if (isNaN(amount)) amount = 0;
                if (isNaN(fee)) fee = 0;

                const saleInfo: SaleInfo = {
                    id: sale.id,
                    amount: amount,
                    createdAt: new Date(sale.createdAt * 1000),
                    paidAt: new Date(sale.paidAt * 1000),
                    fee: fee,
                    payoutId: sale.payoutId,
                    paidOutAt: sale.paidOutAt ? new Date(sale.paidOutDate * 1000) : undefined,
                    type: sale.type,
                    listingId: JSON.parse(sale.typeData).listingId,
                    currency: sale.currency,
                };

                return saleInfo;
            });

            const orderSalesByDate = saleInfos.sort((a, b) => b.paidAt.getTime() - a.paidAt.getTime());
            return orderSalesByDate;
        });
    }

    public static updateMyPoints(): Promise<void> {
        if (!store.getState().accountDomain.loggedIn) return Promise.resolve();

        return ApiUser.getMyPoints().then((points) => {
            store.dispatch(actionGetMyPointsSuccess(points));
        });
    }

    public static getMyPoints(): Promise<number> {
        return this.axios.get('/v1/user/points', { cache: false }).then((res) => {
            return res.data.points;
        });
    }

    public static updateTrackingInfo(): Promise<any> {
        return this.axios.put('/v1/user/tracking-info', {}).then((res) => {
            return res.data;
        });
    }
}
