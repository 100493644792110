import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBasemap } from './selector';
import { Basemap, getBasemap } from './model';
import { actionSetBasemap } from './actions';
import { useLocation } from 'react-router';
import UriHelper from '../../../lib/uri-helper';

export const useBasemap = () => {
    const dispatch = useDispatch();
    const selectedBasemap = useSelector(selectBasemap);
    const defaultBasemap = Basemap.OPEN_STREETMAPS;

    const url = useLocation();

    const setBasemap = useCallback(
        (basemap: Basemap | undefined) => {
            dispatch(actionSetBasemap(basemap));
        },
        [dispatch]
    );

    useEffect(() => {
        // Handle adding or removing basemap from URL
        if (selectedBasemap && selectedBasemap !== defaultBasemap) {
            UriHelper.addParameterToUri(UriHelper.BASEMAP_URI_KEY, selectedBasemap);
        } else if (selectedBasemap === defaultBasemap) {
            UriHelper.removeParameterFromUri('basemap');
        }

        const params = new URLSearchParams(window.location.search);
        const urlSelectedBaseMap = params.get(UriHelper.BASEMAP_URI_KEY);
        const basemapKey = getBasemap(decodeURI(urlSelectedBaseMap || ''));

        if (!basemapKey) {
            setBasemap(defaultBasemap);
        }

        // Handle default basemap from URL
        if (!selectedBasemap) {
            const params = new URLSearchParams(window.location.search);
            const urlSelectedBaseMap = params.get(UriHelper.BASEMAP_URI_KEY);

            if (urlSelectedBaseMap) {
                const decodedBasemap = getBasemap(decodeURI(urlSelectedBaseMap));
                if (!decodedBasemap || !getBasemap(decodedBasemap)) {
                    setBasemap(defaultBasemap);
                }
                setBasemap(decodedBasemap);
            } else {
                setBasemap(defaultBasemap);
            }
        }
    }, [url, selectedBasemap, defaultBasemap, setBasemap]);

    return { basemap: selectedBasemap || defaultBasemap, setBasemap };
};
