import { LayersControl, TileLayer as LeafletTileLayer } from 'react-leaflet';
import Constants from '../../../constants';
import { Basemap } from '../../../store/Map/Basemap/model';
import { useBasemap } from '../../../store/Map/Basemap/use-basemap';
import {
    OSM_DARK,
    OSM_LIGHT,
    ProtomapsTileLayer,
    SOAR_OSM_URL,
} from '../../MapView/TileLayers/ProtoMapTileLayers/protomaps-tile-layer';

const MobileBasemap = () => {
    const { basemap } = useBasemap();
    const OSM_ATTRIBUTION = Constants.MAPS_ATTRIBUTION.OSM_ATTRIBUTION;

    return (
        <LayersControl position="bottomright">
            <LayersControl.BaseLayer name={Basemap.OPEN_STREETMAPS} checked={basemap === Basemap.OPEN_STREETMAPS}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={19} url={Constants.MAPS.OSM_STREET} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.OPEN_ELEVATION} checked={basemap === Basemap.OPEN_ELEVATION}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={13} url={Constants.MAPS.OSM_TOPO} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.STADIA_SATELLITE} checked={basemap === Basemap.STADIA_SATELLITE}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={20} url={Constants.MAPS.STADIA_SATELLITE} />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name={Basemap.STADIA_HYBRID} checked={basemap === Basemap.STADIA_HYBRID}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={20} url={Constants.MAPS.STADIA_HYBRID} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.OSM_DEFAULT_LIGHT} checked={basemap === Basemap.OSM_DEFAULT_LIGHT}>
                <ProtomapsTileLayer url={SOAR_OSM_URL} attribution={OSM_ATTRIBUTION} paintRules={OSM_LIGHT} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.OSM_DEFAULT_DARK} checked={basemap === Basemap.OSM_DEFAULT_DARK}>
                <ProtomapsTileLayer url={SOAR_OSM_URL} attribution={OSM_ATTRIBUTION} paintRules={OSM_DARK} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.WHITE_BLANK} checked={basemap === Basemap.WHITE_BLANK}>
                <LeafletTileLayer url="/assets/basemap/white-pixel.png" />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={Basemap.DARK_BLANK} checked={basemap === Basemap.DARK_BLANK}>
                <LeafletTileLayer url="/assets/basemap/black-pixel.png" />
            </LayersControl.BaseLayer>
        </LayersControl>
    );
};

export default MobileBasemap;
