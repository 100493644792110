import ApiUser from '../../../api/api-user';
import { ListingDTO, UserDTO } from '../../../api/model';
import { actionTypes as at } from './constants';
import { toast } from 'react-toastify';
import UriHelper from '../../../lib/uri-helper';
import { SideDrawerMode } from '../../SideDrawer/model';
import ApiListings from '../../../api/api-listings';
import { AppState } from '../../root-reducer';

export const actionFetchUserProfileWithListings = (userId: string) => {
    return async (dispatch, getState) => {
        const appState: AppState = getState();
        dispatch({
            type: at.FETCH_USER_PROFILE_WITH_LISTINGS_BEGIN,
            payload: userId,
        });

        //to keep cache for profile, just get what's in store
        if (appState.accountDomain.myProfile?.userId === userId) {
            dispatch(actionFetchUserProfileSuccess(appState.accountDomain.myProfile));

            const listings = await ApiListings.getListingsByUserId(appState.accountDomain.myProfile.userId);
            dispatch(actionFetchUserListingsSuccess(listings));
        } else {
            try {
                const userProfile = await ApiUser.getUserProfile(userId);
                dispatch(actionFetchUserProfileSuccess(userProfile));
                if (userProfile.userSeoName) {
                    UriHelper.replacePath(`/profile/${userProfile.userSeoName}`);
                }

                const listings = await ApiListings.getListingsByUserId(userProfile.userId);
                dispatch(actionFetchUserListingsSuccess(listings));
            } catch (err) {
                dispatch({
                    type: at.FETCH_USER_PROFILE_WITH_LISTINGS_ERROR,
                    payload: userId,
                });
                UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
                toast.error('User does not exist');
            }
        }
    };
};

const actionFetchUserProfileSuccess = (user: UserDTO) => {
    return {
        type: at.FETCH_USER_PROFILE_SUCCESS,
        payload: user,
    };
};

const actionFetchUserListingsSuccess = (listings: ListingDTO[]) => {
    return {
        type: at.FETCH_USER_LISTINGS_SUCCESS,
        payload: listings,
    };
};
