import { clarity } from 'react-microsoft-clarity';
import * as Sentry from '@sentry/react';

import ApiUser from '../../api/api-user';
import { ListingDTO, UserDTO } from '../../api/model';

import { actionTypes as at } from './constants';
import { actionShowLoginDialog } from '../App/actions';

import UserHelper from '../../lib/user-helper';

import { CognitoAuthResultType } from '../../lib/cognito-util';
import moment from 'moment';

export const actionGetMyProfile = () => {
    return async (dispatch, getState) => {
        const result: UserDTO = await ApiUser.getMyUserProfile();
        const userRegDate = moment((result.createdAt || 0) * 1000);
        const date5MinutesAgo = moment().subtract(5, 'minutes');
        if (date5MinutesAgo.isBefore(userRegDate)) {
            console.log('tracking-info:updating');
            ApiUser.updateTrackingInfo();
        }
        dispatch(actionGetMyProfileSuccess(result));
        // Check if Clarity has been initialized before calling its methods
        if (clarity.hasStarted()) {
            clarity.identify(result.userId, { name: result.name || '', email: result.email || '' });
        }
        Sentry.setUser({ email: result.email, id: result.userId, username: result.name });
    };
};

export const actionGetMyProfileSuccess = (user: UserDTO) => {
    return {
        type: at.GET_MY_PROFILE_SUCCESS,
        payload: user,
    };
};

export const actionReloadProfile = () => {
    return async (dispatch) => {
        await dispatch(actionGetMyProfile());
    };
};

export const actionLoginSuccess = (auth: CognitoAuthResultType) => {
    return async (dispatch) => {
        UserHelper.saveToken(auth);
        dispatch(actionGetMyProfile());
    };
};

export const actionGetMyListings = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.FETCH_MY_MAPS_ISLOADING,
            payload: true,
        });
        try {
            const result: ListingDTO[] = await ApiUser.getUserListings();
            dispatch(actionGetPilotListingsSuccess(result));
            dispatch(actionShowLoginDialog(false));
        } catch (err) {
            if (/^401/.test(err.message)) {
                dispatch(actionShowLoginDialog(true));
            }
        } finally {
            dispatch({
                type: at.FETCH_MY_MAPS_ISLOADING,
                payload: false,
            });
        }
    };
};

export const actionGetPilotListingsSuccess = (listings: ListingDTO[]) => {
    return {
        type: at.GET_MY_LISTINGS_SUCCESS,
        payload: listings,
    };
};

export const actionGetMyPointsSuccess = (points: number) => {
    return {
        type: at.GET_MY_POINTS_SUCCESS,
        payload: points,
    };
};

export const actionLogoutUser = () => {
    return (dispatch) => {
        UserHelper.resetTokens();
        dispatch({
            type: at.LOGOUT_USER,
        });
    };
};
