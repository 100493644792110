import { AppState } from '../root-reducer';

export const selectConfig = (state: AppState) => state.appDomain.config;

export const selectMaintenance = (state: AppState) => state.appDomain.maintenance;

export const selectFlyToMapPosition = (state: AppState) => state.appDomain.mapPosition;

export const selectShouldMapUpdatePositionImmediately = (state: AppState) =>
    state.appDomain.shouldMapPositionUpdateImmediately;

export const selectShouldMapUpdatePositionAtZoomLevel = (state: AppState) => state.appDomain.mapPositionZoom;

export const selectShowLoginDialog = (state: AppState) => state.appDomain.showLoginDialog;

export const selectOnLoginDialogCloseRedirect = (state: AppState) => state.appDomain.onLoginDialogCloseRedirect;

export const selectShowNavbar = (state: AppState) => state.appDomain.showNavbar;

export const selectMapBounds = (state: AppState) => state.appDomain.mapBounds;

export const selectMapZoom = (state: AppState) => state.appDomain.mapZoom;

export const selectMapMoveEnd = (state: AppState) => state.appDomain.mapMoveEnd;

export const selectShowUploadDialog = (state: AppState) => state.appDomain.showUploadDialog;
